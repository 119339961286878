<template>
  <div  v-if="ready" >
    <detail :cust="customer"></detail>

    <licenses :licenses="customer.licenses" :customerId="customer._id" :customerName="customer.name" :title="true"></licenses>

  </div>
</template>

<script>
  import Licenses from '@/components/Licenses'
  import Detail from '@/components/CustomerDetail'
  export default {
    props: ['id'],
    data () {
      return {
        customer: null,
        customerName: 'Customer',
        ready: false
      }
    },
    computed: {
      
      currentUser () {
        return this.$store.getters.loggedInUser;
      }
    },
    created: async function () {
      //debugger;
      if (!this.$store.state.customers) {
        await this.$store.dispatch('getCustomers')
      }
      let c = this.$clone(this.$store.state.customers[this.id])
      this.customer = c || {}
      this.ready = true;
    },
    components: {'detail': Detail, 'licenses': Licenses}
  }
</script>

<style scoped>

</style>
