<template>
  <home-comp />
</template>

<script>
  import HomeComp from '../components/HomeComp'

  export default {
    name: 'HomeView',

    components: {
      HomeComp,
    },
  }
</script>
