<template>
  <v-card class="ma-10" >
    <v-card-text>
      <v-toolbar color="primary" dark dense>
        <v-icon>mdi-list</v-icon>
        <v-toolbar-title>All Licenses</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          v-model="search"
          clearable
        ></v-text-field>
        <v-tooltip  v-if="currentUser.role === 'admin'" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon 
                  v-bind="attrs"
                  v-on="on" @click="() => { download() }" color="white" >
                <v-icon light>mdi-download</v-icon>
              </v-btn>
              </template>
          <span>Download to CSV</span>
        </v-tooltip>
      </v-toolbar>
      <v-data-table :headers="subHeaders"
                    :items="items"
                    item-key="licenseId"
                    :items-per-page="itemLimit"
                    hide-default-footer
                    v-bind:search="search"
                    @current-items="currentItems"
                    :loading="loading"
                    >

        <template v-slot:item.z="{ item }">
          <span class="text-no-wrap"> 
           
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon 
                  v-bind="attrs"
                  v-on="on"
               @click="viewStats(item)">
                <v-icon>mdi-tune</v-icon>
              </v-btn>
              </template>
              <span>View Stats</span>
            </v-tooltip>
            
          </span>
        </template>
        <template v-slot:item.activated="{ item }">
          <span class="text-no-wrap"> {{ item.activated | moment("dddd, MMMM Do YYYY") }}</span>
        </template>
        <template v-slot:item.expires="{ item }">
          <span class="text-no-wrap"> {{ item.expires | moment("dddd, MMMM Do YYYY") }}</span>
        </template>
        
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    props: [],
    data () {
      return {
        loading: true,
        items: [],
        filteredItems: [],
        itemLimit: -1,
        search: '',
        subHeaders: [
          {text: 'Actions', align: 'left', value:"z", sortable: false},
          {value: 'version', text: 'Version'},
          {value: 'customer.name', text: 'Customer'},
          {value: 'instanceId', text: 'InstanceID'},
          {value: 'licenseLocation', text: 'Location'},
          {value: 'licenseId', text: 'LicenseID'},
          {value: 'activated', text: 'Activated'},
          {value: 'expires', text: 'Expires'}
        ],
        currentUser: this.$store.getters.loggedInUser
      }
    },
    created: function () {
    },
    methods: {
      currentItems: function(value){
          this.filteredItems = value
      },
      download () {
        this.$downloadCSV({data: this.filteredItems, filename: 'licenses.csv'})
      },
      getItems () {
        this.loading = 'success'
        this.$http.reports.licenses().then(response => {
          this.items = response.data
          this.ready = true
          this.loading = false
        })
      },
      viewStats: function (license) {
        let bucket = new Date().toISOString().substr(0, 7)
        this.$router.push({name: 'LicenseStats', params: { customerName: license.customer.name, customerId: license.customer._id, licenseId: license.licenseId, instanceId: license.instanceId, bucket: bucket }})
      }
    },
    mounted: function () {
      this.getItems()
    }
  }
</script>

<style scoped>
  .bold {
    font-weight: bold;
  }
</style>
