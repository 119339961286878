import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import http from './utils/http'
import VueMoment from 'vue-moment';
Vue.use(VueMoment)
Vue.config.productionTip = false
Vue.prototype.$http = http
Vue.prototype.$clone = function (obj) {
  return JSON.parse(JSON.stringify(obj))
}
Vue.prototype.$toArr = function (obj) {
  return Object.keys(obj).map((i) => obj[i])
}
function convertArrayOfObjectsToCSV (args) {
  var result, ctr, keys, columnDelimiter, lineDelimiter, data

  data = args.data || null
  if (data == null || !data.length) {
    return null
  }
  columnDelimiter = args.columnDelimiter || ','
  lineDelimiter = args.lineDelimiter || '\n'
  keys = Object.keys(data[0])
  result = ''
  result += keys.join(columnDelimiter)
  result += lineDelimiter
  data.forEach(function (item) {
    ctr = 0
    keys.forEach(function (key) {
      if (ctr > 0) result += columnDelimiter

      result += item[key]
      ctr++
    })
    result += lineDelimiter
  })

  return result
}

Vue.prototype.$downloadCSV = function (args) {
  var data, filename, link

  var csv = convertArrayOfObjectsToCSV({
    data: args.data
  })
  if (csv == null) return

  filename = args.filename || 'export.csv'

  if (!csv.match(/^data:text\/csv/i)) {
    csv = 'data:text/csv;charset=utf-8,' + csv
  }
  data = encodeURI(csv)

  link = document.createElement('a')
  link.setAttribute('href', data)
  link.setAttribute('download', filename)
  link.click()
}

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
