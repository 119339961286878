<template>
  <v-card class="ma-10" >
    <v-card-text>
      <v-toolbar v-if="title" color="white" light dense>
        <v-toolbar-title class="black--text">Licenses</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip v-if="currentUser.role === 'admin'" bottom>
          <template v-slot:activator="{ on, attrs }">
                <v-btn   
                color="light-blue"
                light
                small
                absolute
                bottom
                right
                fab
                  v-bind="attrs"
                  v-on="on"
                 @click="editLicense({})">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          </template>
          <span>Add License</span>
        </v-tooltip>

      </v-toolbar>
      <v-data-table :headers="subHeaders"
                    :items="licenseArr"
                    :items-per-page="itemLimit"
                    item-key="item.intanceId"
                    hide-default-footer>
        <template v-slot:item.z="{ item }">
          <span class="text-no-wrap"> 
            <v-tooltip  v-if="currentUser.role === 'admin'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon 
                  v-bind="attrs"
                  v-on="on"
                  @click="editLicense(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit License</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon 
                  v-bind="attrs"
                  v-on="on"
               @click="viewStats(item)">
                <v-icon>mdi-tune</v-icon>
              </v-btn>
              </template>
              <span>View Stats</span>
            </v-tooltip>
            <v-tooltip v-if="currentUser.role === 'admin'" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon 
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteLicense(item)">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
              </template>
              <span>Delete License</span>
            </v-tooltip>
          </span>
        </template>
        <template v-slot:item.userLimit="{ item }">
          <span class="text-xs-right">{{ item.userLimit }}</span>
        </template>
        <template v-slot:item.apiLimit="{ item }">
          <span class="text-xs-right">{{ item.apiLimit }}</span>
        </template>
        <template v-slot:item.startDate="{ item }">
          <span class="text-xs">{{ item.startDate | moment("YYYY-MM-DD") }}</span>
        </template>
        <template v-slot:item.expires="{ item }">
          <span class="text-xs">{{ item.expires | moment("YYYY-MM-DD") }}</span>
        </template>
        <template v-slot:item.activated="{ item }">
          <span class="text-xs">{{ item.activated | moment("YYYY-MM-DD") }}</span>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="ddialog" max-width="590">
      <v-card>
        <v-card-title class="headline"><v-icon color="red">mdi-alert</v-icon> Do you really want to delete this license?</v-card-title>
        <v-card-text>This will completely delete the license.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" @click.native="ddialog = false">No!</v-btn>
          <v-btn color="green darken-1" @click="removeLicense">Yes. I know what I'm doing.</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="editableLicense"
      v-model="edialog"
      fullscreen
      transition="dialog-bottom-transition"
      :overlay="false"
      scrollable
    >
      <v-card>
        <v-card-title>

          <v-toolbar color="primary" dense>
            <v-toolbar-title class="white--text">Edit License</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click.stop="edialog=false" color="primary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

        </v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-container grid-list-md text-xs-center>
              <v-layout row wrap>
                <v-flex xs4>
                  <v-text-field
                    label="License Key"
                    v-model="editableLicense._id"
                    readonly
                  ></v-text-field>
                  <v-text-field
                    label="Instance ID"
                    v-model="editableLicense.instanceId"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Version"
                    v-model="editableLicense.version"
                  ></v-text-field>
                  <v-select
                    :items="licenseLocations"
                    v-model="editableLicense.licenseLocation"
                    label="License Location"
                  ></v-select>

                  <v-select
                    :items="licenseTypes"
                    v-model="editableLicense.licenseType"
                    label="License Type"
                  ></v-select>
                  <v-select
                    :items="userTypes"
                    v-model="editableLicense.userType"
                    label="User Type"
                  ></v-select>
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    type="number"
                    label="Servers Allowed"
                    v-model="editableLicense.serversAllowed"
                    required
                  ></v-text-field>
                  <v-text-field
                    type="number"
                    label="User Limit"
                    v-model="editableLicense.userLimit"
                    required
                  ></v-text-field>
                  <v-text-field
                    type="number"
                    label="API Limit"
                    v-model="editableLicense.apiLimit"
                    required
                  ></v-text-field>
                  <v-menu
                    
                    :close-on-content-click="true"
                    v-model="sdMenu"
                    transition="scale-transition"
                    offset-y
                    
                    :nudge-right="40"
                    max-width="290px"
                    min-width="290px"
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      label="Start Date"
                      v-model="editableLicense.startDateF"
                      prepend-icon="mdi-calendar"
                      required
                    ></v-text-field>
                    </template>
                    <v-date-picker v-model="editableLicense.startDateF" no-title scrollable autosave>

                    </v-date-picker>
                  </v-menu>
                  <v-menu
                    :close-on-content-click="true"
                    v-model="expiresMenu"
                    transition="scale-transition"
                    offset-y
                    
                    :nudge-right="40"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        label="Expires"
                        v-model="editableLicense.expiresF"
                        prepend-icon="mdi-calendar"
                        required
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="editableLicense.expiresF" no-title scrollable autosave>

                    </v-date-picker>
                  </v-menu>

                  <v-select
                    label="Plugins"
                    :items="plugins"
                    v-model="editableLicense.plugins"
                    multiple
                    item-text="name"
                    item-value="plugin_id"
                    max-height="400"
                    chips
                    return-object
                  ></v-select>
                  <v-select
                    label="Apps"
                    :items="apps"
                    v-model="editableLicense.apps"
                    multiple
                    item-text="name"
                    item-value="app_id"
                    max-height="400"
                    chips
                    return-object
                  ></v-select>
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    type="number"
                    label="Critical (Normal Hrs)"
                    v-model="editableLicense.criticalResponseTime"
                  ></v-text-field>
                  <v-text-field
                    type="number"
                    label="Critical (After Hrs)"
                    v-model="editableLicense.criticalResponseTimeAfterHours"
                  ></v-text-field>
                  <v-text-field
                    type="number"
                    label="Non Critical (Normal Hrs)"
                    v-model="editableLicense.nonCriticalResponseTime"
                  ></v-text-field>
                  <v-switch
                    :label="`Email Monitor: ${editableLicense.emailMonitor.toString()}`"
                    v-model="editableLicense.emailMonitor"
                  ></v-switch>
                  <v-switch
                    :label="`Upgrades Included: ${editableLicense.upgradesIncluded.toString()}`"
                    v-model="editableLicense.upgradesIncluded"
                  ></v-switch>
                  <v-checkbox
                    :label="`Keep Active After Save`"
                    v-model="saveAndKeepActive"
                  ></v-checkbox>

                  <v-select
                    label="Modules"
                    :items="modules"
                    v-model="editableLicense.modules"
                    multiple
                    item-text="name"
                    item-value="module_id"
                    max-height="400"
                    chips
                    return-object
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-container>

          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="saveLicense"

            color="primary"
          >
            Save
          </v-btn>
          <v-btn color="secondary" @click.stop="edialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
    export default {
      name: "Licenses",
      props: ['licenses', 'title', 'customerId', 'customerName'],
      data () {
        return {
          itemLimit: -1,
          licenseArr: [],
          edialog: false,
          ddialog: false,
          sdMenu: false,
          expiresMenu: false,
          saveAndKeepActive: false,
          valid: false,
          editableLicense: null,
          deleteableLicense: null,
          licenseTypes: ['standard', 'trial', 'sandbox'],
          licenseLocations: ['cloud', 'onpremise', 'privatecloud'],
          userTypes: ['concurrent', 'named'],
          subHeaders: [
            {text: 'Actions', align: 'left', value:"z", sortable: false},
            {text: 'Key', value: '_id', align: 'left'},
            {text: 'Instance ID', value: 'instanceId', align: 'left'},
            {text: 'Location', value: 'licenseLocation', align: 'left'},
            {text: 'Type', value: 'licenseType', align: 'left'},
            {text: 'User Type', value: 'userType', align: 'left'},
            {text: 'User Limit', value: 'userLimit', align: 'left'},
            {text: 'API Limi', value: 'apiLimit', align: 'left'},
            {text: 'Starts', value: 'startDate', align: 'left'},
            {text: 'Expires', value: 'expires', align: 'left'},
            {text: 'Activated', value: 'activated', align: 'left'}
          ],
          currentUser: this.$store.getters.loggedInUser
        }
      },
      methods: {
        deleteLicense: function (license) {
          this.ddialog = true
          this.deleteableLicense = license
          this.deleteableLicense.customerId = this.customerId
        },
        removeLicense: function () {
          this.$store.dispatch('deleteLicense', this.deleteableLicense).then((cust) => {
            this.licenseArr = this.$toArr(cust.licenses)
            this.ddialog = false
          })
          // this.$router.push({name: 'Customers'})
        },
        editLicense: function (license) {
          this.editableLicense = license
          this.editableLicense.startDateF = this.$moment(this.editableLicense.startDate).format('YYYY-MM-DD')
          this.editableLicense.expiresF = this.$moment(this.editableLicense.expires).format('YYYY-MM-DD')
          this.editableLicense.keepActiveAfterSave = false
          // set defaults
          if (!license._id) {
            this.editableLicense._id = 'pending'
            this.editableLicense.expiresF = this.$moment(this.editableLicense.expires).add(1, 'years').format('YYYY-MM-DD')
            this.editableLicense.isNew = true
            this.editableLicense.serversAllowed = 1
            this.editableLicense.criticalResponseTime = 5
            this.editableLicense.nonCriticalResponseTime = 10
            this.editableLicense.emailMonitor = true
            this.editableLicense.upgradesIncluded = true
            this.saveAndKeepActive = false
            let pluginListClone = JSON.parse(JSON.stringify(this.plugins))
            let moduleListClone = JSON.parse(JSON.stringify(this.modules))
            this.editableLicense.plugins = pluginListClone.filter((l) => {
              return (l.name !== 'command line' && l.name !== 'upload to sharepoint' && l.name !== 'local file copy' && l.name !== 'custom grid' && l.plugintype !== 'x-unreleased' && l.plugintype !== 'x-obsolete' && l.plugintype !== 'x-custom')
            })
            this.editableLicense.modules = moduleListClone
            let appListClone = JSON.parse(JSON.stringify(this.apps))
            this.editableLicense.apps = appListClone
            this.editableLicense.userLimit = 50
            this.editableLicense.apiLimit = 50000
            this.editableLicense.licenseLocation = 'cloud'
            this.editableLicense.userType = 'concurrent'
            this.editableLicense.licenseType = 'standard'
          }
          this.edialog = true
        },
        saveLicense: function () {
          if (!this.saveAndKeepActive) {
            this.editableLicense.activated = null
          }
          this.editableLicense.customerId = this.customerId
          this.editableLicense.startDate = this.$moment(this.editableLicense.startDateF).toDate()
          this.editableLicense.expires = this.$moment(this.editableLicense.expiresF).toDate()
          this.$store.dispatch('saveLicense', this.editableLicense).then((cust) => {
            this.licenseArr = this.$toArr(cust.licenses)
          })
          this.edialog = false
        },
        viewStats: function (license) {
          let bucket = new Date().toISOString().substr(0, 7)
          this.$router.push({name: 'LicenseStats', params: { customerName: this.customerName, customerId: this.customerId, licenseId: license._id, instanceId: license.instanceId, bucket: bucket }})
        }
      },
      computed: {
        plugins () {
          return this.$store.state.plugins
        },
        modules () {
          return this.$store.state.modules
        },
        apps () {
          return this.$store.state.apps
        }
      },
      mounted: function () {
        this.licenseArr = Object.keys(this.licenses).map((i) => this.licenses[i])
      }
    }
</script>

<style scoped>

</style>
