<template>
  <div>
    <v-card class="ma-10" >
      <v-card-text>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="white--text">{{ editableCustomer.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip v-if="currentUser.role === 'admin'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon 
                  v-bind="attrs"
                  v-on="on"
                  @click.native.stop="edialog = true" dark>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              </template>
            <span>Edit Customer</span>
          </v-tooltip>
          <v-tooltip v-if="currentUser.role === 'admin'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon 
                v-bind="attrs"
                v-on="on"
                @click.native.stop="ddialog = true" dark>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete Customer</span>
          </v-tooltip>
        </v-toolbar>
        <v-card color="white">
          <v-card-text class="px-0 ml-10">
            <table width="100%">
              <tr class="subheading">
                <td>ID: {{editableCustomer._id}}</td>
                <td>Type: {{editableCustomer.customerType}}</td>
                <td>Active: {{editableCustomer.active}}</td>
                <td>Created: {{editableCustomer.created | moment("dddd, MMMM Do YYYY, h:mm:ss a")}}</td>
                <td>Updated: {{editableCustomer.updated | moment("dddd, MMMM Do YYYY, h:mm:ss a")}}</td>
              </tr>
            </table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <v-dialog v-model="ddialog" max-width="590">
      <v-card>
        <v-card-title class="headline"><v-icon color="red">mdi-alert</v-icon> Do you really want to delete {{ customer.name }}?</v-card-title>
        <v-card-text>This will completely delete the customer and all of their licenses.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1"  @click.native="ddialog = false">No!</v-btn>
          <v-btn color="green darken-1"  @click="deleteCustomer">Yes. I know what I'm doing.</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="edialog" max-width="500px">
      <v-card>
        <v-card-title>
          Edit {{ customer.name}}
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-text-field
              label="Name"
              v-model="editableCustomer.name"
              required
            ></v-text-field>
            <v-select
              label="Type"
              v-model="editableCustomer.customerType"
              :items="customerTypes"
              required
            ></v-select>
            <v-checkbox
              label="Active?"
              v-model="editableCustomer.active"
            ></v-checkbox>

          </v-form>
        </v-card-text>
        <v-card-actions>

          <v-btn
            @click="submit"
            
            color="primary"
          >
            Save
          </v-btn>
          <v-btn color="secondary"  @click.stop="edialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
    export default {
      name: 'customereditableCustomer-detail',
      props: ['cust'],
      data () {
        return {
          edialog: false,
          ddialog: false,
          valid: false,
          customerTypes: ['Customer', 'Partner', 'Prospect'],
          customerObj: null,
          
        }
      },
      computed: {
        customer () {
         
          return this.cust
        },
        currentUser () {
          return this.$store.getters.loggedInUser;
        }
      },
      methods: {
        submit: function () {
          this.$store.dispatch('updateCustomer', this.editableCustomer).then((updated) => {
            debugger;
            this.editableCustomer = updated
          })
          this.edialog = false
        },
        deleteCustomer: function () {
          this.$store.dispatch('deleteCustomer', this.customer)
          this.$store.dispatch('getCustomers')
          this.ddialog = false
          this.$router.push("/customers")
        }
      },
      created: function () {
        this.editableCustomer = this.$clone(this.cust)
      }
    }
</script>

<style scoped>

</style>
