import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Customers from '../components/Customers.vue'
import Login from '../components/Login.vue'
import Customer from "../components/Customer"
import LicenseStats from '../components/LicenseStats'
import CombinedStats from '../components/CombinedStats'
import Users from '../components/Users'
import LicenseReport from '../components/LicenseReport'
import FeatureFlags from '@/components/FeatureFlags'
import Notifications from '@/components/Notifications'
import Environments from '@/components/Environments'
import Environment from '@/components/Environment'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/customers',
    name: 'customers',
    component: Customers
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/stats',
    name: 'CurrentStats',
    component: CombinedStats,
    props: true
  },
  {
    path: '/stats/:pbucket',
    name: 'Stats',
    component: CombinedStats,
    props: true
  },
  {
    path: '/customers/:id',
    name: 'Customer',
    component: Customer,
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/customers/:customerId/:licenseId/:instanceId/stats/:bucket',
    name: 'LicenseStats',
    component: LicenseStats,
    props: true
  },
  {
    path: '/reports/licenses',
    name: 'LicenseReport',
    component: LicenseReport
  },
  {
    path: '/environments',
    name: 'Environments',
    component: Environments
  },
  {
    path: '/environment/:mongoId',
    name: 'Environment',
    component: Environment,
    props: true
  },
  {
    path: '/featureflags',
    name: 'FeatureFlags',
    component: FeatureFlags
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications
  }
 
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (!sessionStorage.getItem('token') && to.name !== 'Login') {
    sessionStorage.setItem('destination', JSON.stringify(to))
    return next({ path: '/login' })
  }
  next()
})

export default router
