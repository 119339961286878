<template>
  <v-card v-if="ready" class="ma-10" >
    <v-card-title>
      <v-toolbar dark color="primary">
        <v-btn v-if="currentUser.role === 'admin'" class="ml-5"
          color="light-blue"
          dark
          small
          absolute
          bottom
          left
          fab
          @click.native.stop="edialog = !edialog"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-toolbar-title class="white--text">
            Customers
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-row no-gutters>
          <v-col class="mt-5">
            
            <v-radio-group
              v-model="customerType"
              row
              
            >
            
              <v-radio
                label="Customer"
                value="Customer"
              ></v-radio>
              <v-radio
                label="Partner"
                value="Partner"
              ></v-radio>
              <v-radio
                label="Prospect"
                value="Prospect"
              ></v-radio>
              <v-radio
                label="All"
                value="all"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col class="mt-5">
            
            <v-radio-group
              v-model="displayItems"
              row
              
            >
            
              <v-radio
                label="Active"
                value="active"
              ></v-radio>
              <v-radio
                label="Inactive"
                value="inactive"
              ></v-radio>
              <v-radio
                label="All"
                value="all"
              ></v-radio>
            </v-radio-group>
          
          </v-col>
          <v-col class="mt-3">
            <v-text-field
              append-icon="mdi-magnify"
              label="Search"
              row
              hide-details
              clearable
              v-model="search"
            ></v-text-field>
          </v-col>
      </v-row>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-bind:headers="headers"
        v-bind:items="filteredItems"
        v-bind:search="search"
        item-key="_id"
        show-expand
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-chevron-double-left',
          lastIcon: 'mdi-chevron-double-right',
          prevIcon: 'mdi-chevron-left',
          nextIcon: 'mdi-chevron-right',
          itemsPerPageOptions: [20,30,40,50]
        }"
      >
        <template v-slot:item._id="{ item }">
          <span class="text-no-wrap"> 
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon 
                  v-bind="attrs"
                  v-on="on"
                  @click="viewCustomer(item)">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              <span>{{ 'View Customer' }}</span>
            </v-tooltip>
          
          </span>
        </template>
        <template v-slot:item.created="{item}">
          <span class="text-no-wrap">{{ item.created | moment("dddd, MMMM Do YYYY") }}</span>
        </template>

        <template v-slot:item.updated="{item}">
          <span class="text-no-wrap">{{ item.updated | moment("dddd, MMMM Do YYYY") }}</span>
        </template>
      
        <template v-slot:expanded-item="{headers,item}">

           <td :colspan="headers.length" class="pa-5">
             <v-container fluid>
               <my-licenses :licenses="item.licenses" :customerId="item._id"></my-licenses>
            </v-container>
          </td>
        </template>
      </v-data-table>
      <v-dialog v-model="edialog" max-width="500px">
        <v-card>
          <v-card-title>
            Add Customer
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-text-field
                label="Name"
                v-model="editableCustomer.name"
                required
              ></v-text-field>
              <v-select
                label="Type"
                v-model="editableCustomer.customerType"
                :items="customerTypes"
                required
              ></v-select>
              <v-checkbox
                label="Active?"
                v-model="editableCustomer.active"
              ></v-checkbox>

            </v-form>
          </v-card-text>
          <v-card-actions>

            <v-btn
              @click="submit"
              
              color="primary"
            >
              Save
            </v-btn>
            <v-btn color="secondary"  @click.stop="edialog=false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>


<script>
  import Licenses from '@/components/Licenses'
  export default {
    name: 'Customers',
    components: {
      'my-licenses': Licenses
    },
    data () {
      return {
        tmp: '',
        search: '',
        ready: false,
        pagination: {rowsPerPage: 10},
        valid: false,
        editableCustomer: {},
        customerTypes: ['Customer', 'Partner', 'Prospect'],
        edialog: false,
        headers: [
          {text: 'Actions', align: 'left', value:"_id", sortable: false},
          {
            text: 'Name',
            value: 'name',
            align: 'left'
          },
          {text: 'Type', value: 'customerType', align: 'left'},
          {text: 'Created', value: 'created', align: 'left'},
          {text: 'Updated', value: 'updated', align: 'left'},
          {text: 'Active', value: 'active', align: 'left'},
          {text: 'Licenses', value: 'licenseLength', align: 'left'}
        ],
        items: [],
        customers: {},
        loading: true,
        displayItems: 'active', //inactive, alll
        customerType: 'all' //, Customer,Prospect, Partner
      }
    },
    computed: {
      currentUser() {
        return this.$store.getters.loggedInUser
      },
      filteredItems() {
        return this.items.filter((i) => {
          if (this.displayItems == 'active') {
            return i.active && (this.customerType != "all" ? (i.customerType == this.customerType): true);
          }
          if (this.displayItems == 'inactive' ) {
            return !i.active && (this.customerType != "all" ? (i.customerType == this.customerType): true);
          }
          if (this.displayItems == 'all') {
            return true && (this.customerType != "all" ? (i.customerType == this.customerType): true);
          }
         
        })
      }
    },
    methods: {
      viewCustomer: function (customer) {
        //console.log(customer)
        this.$router.push({name: 'Customer', params: { id: customer._id }})
      },
      submit: async function () {
        let me = this
        await this.$store.dispatch('addCustomer', this.editableCustomer);
       
        await this.$store.dispatch('getCustomers')
        me.customers = me.$store.getters.customers
        this.items = this.$store.getters.customerIds.map((i) => {
          let c = this.customers[i]
          c.licenseLength = Object.keys(c.licenses).length;
          return c;
        })
        this.edialog = false
      }
    },
    mounted: async function () {
      this.$http.customer.list().then(response => {
        this.$store.dispatch('setCustomers', response.data)
        this.customers = this.$store.state.customers
        this.items = this.$store.getters.customerIds.map((i) => {
          let c = this.customers[i]
          c.licenseLength = Object.keys(c.licenses).length;
          return c;
        })



        this.ready = true
      })
    }
  }
</script>

<style scoped>

</style>
