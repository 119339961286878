<template>
  <v-card class="ma-10" >
    <v-card-text>
      <v-toolbar color="primary" dark dense>
        <v-icon>mdi-tune</v-icon>
        <v-toolbar-title>Licenses Stats for {{customerName }} / {{instanceId}} / {{this.bucket}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip  top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon 
                  v-bind="attrs"
                  v-on="on" @click="() => { dir('previous') }" color="white" >
              <v-icon light>mdi-arrow-left</v-icon>
              </v-btn>
          </template>
          <span>Previous</span>
        </v-tooltip>
        <v-tooltip  top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon 
                  v-bind="attrs"
                  v-on="on" @click="() => { dir('next') }" color="white" >
              <v-icon light>mdi-arrow-right</v-icon>
              </v-btn>
          </template>
          <span>Next</span>
        </v-tooltip>
        <v-tooltip  top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon 
                  v-bind="attrs"
                  v-on="on" @click="(event) => { download(event) }" color="white" >
                <v-icon light>mdi-download</v-icon>
              </v-btn>
              </template>
          <span>Download to CSV</span>
        </v-tooltip>
      </v-toolbar>
      <v-tabs>
        <v-tab>User Stats</v-tab>
        <v-tab-item>
          <v-data-table :headers="subHeaders"
                    :items="items"
                    :items-per-page="itemLimit"
                    item-key="item.licenseId"
                    hide-default-footer
                    :loading="loading"
                    @current-items="currentItems">
          <template v-slot:item.overage="{ item }">
            <span class="text-xs-right" v-bind:class="{ 'red--text bold': item.overage > 0 }"> 
              {{ item.overage }}
            </span>
          </template>
          <template v-slot:item.concurrentAvg="{ item }">
            <span class="text-xs-right" > 
              {{ item.concurrentAvg | round }}
            </span>
          </template>
          <template v-slot:item.guestConcurrentAvg="{ item }">
            <span class="text-xs-right" > 
              {{ item.guestConcurrentAvg | round }}
            </span>
          </template>
          <template v-slot:item.datePosted="{ item }">
            <span class="text-xs">{{ item.datePosted | moment("YYYY-MM-DD hh:mm A") }}</span>
          </template>
      </v-data-table>
        </v-tab-item>
        <v-tab>Task Executions</v-tab>
        <v-tab-item>
          <v-data-table :headers="subHeaders2"
                    :items="items"
                    :items-per-page="itemLimit"
                    item-key="item.licenseId"
                    hide-default-footer
                    :loading="loading"
                    @current-items="currentItems"></v-data-table>
        </v-tab-item>
      </v-tabs>

    </v-card-text>
  </v-card>
</template>

<script>
    export default {
      props: ['licenseId', 'instanceId', 'bucket', 'customerId', 'customerName'],
      data () {
        return {
          loading: true,
          items: [],
          itemLimit: -1,
          filteredItems: [],
          breadCrumbs: [],
          subHeaders: [
            {text: 'InstanceId', value: 'instanceId', align: 'left'},
            {text: 'Location', value: 'licenseLocation', align: 'left'},
            {text: 'Type', value: 'licenseType', align: 'left'},
            {text: 'User Type', value: 'userType', align: 'left'},
            {text: 'Overage', value: 'overage', align: 'right'},
            {text: 'MAX CU', value: 'concurrentMax', align: 'right'},
            {text: 'MIN CU', value: 'concurrentMin', align: 'right'},
            {text: 'AVG CU', value: 'concurrentAvg', align: 'right'},
            {text: 'MAX Guest CU', value: 'guestConcurrentMax', align: 'right'},
            {text: 'MIN Guest CU', value: 'guestConcurrentMin', align: 'right'},
            {text: 'AVG Guest CU', value: 'guestConcurrentAvg', align: 'right'},
            {text: 'User Limit', value: 'userLimit', align: 'right'},
            {text: 'Processes', value: 'processCount', align: 'right'},
            {text: 'Requests', value: 'instanceCount', align: 'right'},
            {text: 'Users', value: 'userCount', align: 'right'},
            {text: 'Date Posted', value: 'datePosted', align: 'left'}
          ],
          subHeaders2: [
            {text: 'InstanceId', value: 'instanceId', align: 'left'},
            {text: 'Location', value: 'licenseLocation', align: 'left'},
            {text: 'Type', value: 'licenseType', align: 'left'},
            {text: 'User Type', value: 'userType', align: 'left'},
            {text: 'Approval', value: 'approvalCount', align: 'right'},
            {text: 'Aws Lambda', value: 'awsLambdaCount', align: 'right'},
            {text: 'Counter', value: 'counterCount', align: 'right'},
            {text: 'Data Container', value: 'dataContainerCount', align: 'right'},
            {text: 'DB Pull', value: 'databasePullCount', align: 'right'},
            {text: 'DB Push', value: 'databasePushCount', align: 'right'},
            {text: 'Developer Form', value: 'developerFormCount', align: 'right'},
            {text: 'Dynamic Assigner', value: 'dynamicAssignerCount', align: 'right'},
            {text: 'Dynamic Notification', value: 'dynamicNotificationCount', align: 'right'},
            {text: 'Excel Batch', value: 'excelBatchCount', align: 'right'},
            {text: 'Excel Import', value: 'excelImportCount', align: 'right'},
            {text: 'File Copy', value: 'fileCopyCount', align: 'right'},
            {text: 'Form Assigner', value: 'formAssignerCount', align: 'right'},
            {text: 'FTP Push', value: 'ftpPushCount', align: 'right'},
            {text: 'General Notification', value: 'generalNotificationCount', align: 'right'},
            {text: 'Launch Request', value: 'launchRequestCount', align: 'right'},
            {text: 'Milestone', value: 'milestoneCount', align: 'right'},
            {text: 'Office Templating', value: 'officeTemplateCount', align: 'right'},
            {text: 'Open API', value: 'openApiCount', align: 'right'},
            {text: 'Open Url', value: 'openUrlCount', align: 'right'},
            {text: 'PDF Generator', value: 'pdfGeneratorCount', align: 'right'},
            {text: 'PDF Merge', value: 'pdfMergeCount', align: 'right'},
            {text: 'Power Form', value: 'powerFormCount', align: 'right'},
            {text: 'Rest Client', value: 'restClientCount', align: 'right'},
            {text: 'Task Assigner', value: 'taskAssignerCount', align: 'right'},
            {text: 'Task Scheduler', value: 'taskSchedulerCount', align: 'right'},
            {text: 'User Profile', value: 'userProfileCount', align: 'right'},
            {text: 'Web Service', value: 'webServiceCount', align: 'right'},
            {text: 'Date Posted', value: 'datePosted', align: 'left'}
          ],
          currentUser: this.$store.getters.loggedInUser
        }
      },
      created: function () {
      },
      methods: {
        download () {
          this.$downloadCSV({data: this.filteredItems, filename: this.instanceId + '-' + this.bucket + '.csv'})
        },
        dir ( dir) {
          let dt = this.$moment(this.bucket)
          if (dir === 'previous') {
            let bucket = dt.subtract(1, 'months').toISOString().substr(0, 7)
            this.$router.push({name: 'LicenseStats', params: { customerName: this.customerName, customerId: this.customerId, licenseId: this.licenseId, instanceId: this.instanceId, bucket: bucket }})
          }
          if (dir === 'next') {
            let bucket = dt.add(1, 'months').toISOString().substr(0, 7)
            this.$router.push({name: 'LicenseStats', params: { customerName: this.customerName, customerId: this.customerId, licenseId: this.licenseId, instanceId: this.instanceId, bucket: bucket }})
          }
          // this.getItems()
        },
        getItems () {
          this.loading = 'success'
          this.$http.stats.license({licenseId: this.licenseId, instanceId: this.instanceId, bucket: this.bucket}).then(response => {
            this.items = response.data
            this.ready = true
            this.loading = false
          })
        },
        currentItems: function(value){
          this.filteredItems = value
        }
      },
      mounted: function () {
        this.getItems()
      },
      filters: {
        round: function (value) {
          if (!value) return 0
          return Math.round(Number(value))
        }
      }
    }
</script>

<style scoped>
  .bold {
    font-weight: bold;
  }
</style>
