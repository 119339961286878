<template>
  <v-card v-if="ready" class="ma-10" >
    <v-card-title>
      <v-toolbar dark color="primary" >
        <v-btn v-if="currentUser.role === 'admin'" class="ml-5"
          color="light-blue"
          dark
          small
          absolute
          bottom
          left
          fab
          @click.native.stop="edialog = !edialog"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-toolbar-title class="white--text">
            Environments
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
          v-model="search"
        ></v-text-field>

      </v-toolbar>
    </v-card-title>
    <v-data-table
      v-bind:headers="headers"
      v-bind:items="items"
      v-bind:search="search"
      item-key="_id"
      :items-per-page="itemLimit"
      hide-default-footer
    > 
      <template v-slot:item.z="{ item }">
          <span class="text-no-wrap"> 
        
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon 
                  v-bind="attrs"
                  v-on="on"
              @click="viewEnv(item) ">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              </template>
              <span>View Environment</span>
            </v-tooltip>
           
          </span>
        </template>
        <template v-slot:item.featureFlags="{ item }">
          <span class="text-no-wrap"> 
            {{ item.featureFlags.map(f => f.id + ": " + f.active + ",") }}
            </span>
        </template>
   
    </v-data-table>
    <v-dialog v-model="edialog" max-width="500px">
      <v-card>
        <v-card-title>
          Add Environment
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-text-field
              label="Environment ID"
              v-model="editableEnvironment.environmentId"
              required
            ></v-text-field>
            <v-text-field
              label="Description"
              v-model="editableEnvironment.description"
              required
            ></v-text-field>
 

          </v-form>
        </v-card-text>
        <v-card-actions>

          <v-btn
            @click="submit"
            
            color="primary"
          >
            Save
          </v-btn>
          <v-btn color="secondary" @click.stop="edialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>


<script>
  // import FeatureFlags from '@/components/FeatureFlags'
  export default {
    // components: {
    //   'my-featureFlags': FeatureFlags
    // },
    data () {
      return {
        tmp: '',
        search: '',
        ready: false,
        pagination: {rowsPerPage: 10},
        valid: false,
        editableEnvironment: {},
        edialog: false,
        headers: [
          {text: 'Actions', align: 'left',  value:"z", sortable: false},
          {
            text: 'ID',
            value: 'environmentId',
            align: 'left'
          },
          {text: 'Description', value: 'description', align: 'left'},
          {text: 'Feature Flags', value: 'featureFlags.length', align: 'left'}
        ],
        items: [],
        itemLimit: -1,
        environments: {},
        loading: true,
        currentUser: this.$store.getters.loggedInUser,
      }
    },
    methods: {
      viewEnv (item) {
        this.$router.push({name: 'Environment', params: {mongoId: item._id}})
      },
      async submit () {
        const env = this.editableEnvironment
        await this.$http.environment.add(env);
        this.editableEnvironment = {}
        this.edialog = false
        await  this.getEnvironments();
      
      },
      async getEnvironments () {
        const response = await this.$http.environment.list()
        this.items = response.data
        this.ready = true
      }
    },
    async created () {
      await this.getEnvironments()
    }
  }
</script>

<style scoped>

</style>
