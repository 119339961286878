<template>
  <v-card class="ma-10" >
    <v-card-title>
        <v-toolbar dark color="primary">
          <v-btn v-if="currentUser.role === 'admin'" class="ml-5"
                color="light-blue"
                dark
                small
                absolute
                bottom
                left
                fab
                @click="editFeatureFlag({})" 
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-toolbar-title class="white--text">
            Feature Flags
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
            clearable
          ></v-text-field>

          </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="subHeaders"
                      :items="featureFlagArr"
                      :items-per-page="itemLimit"
                      v-bind:search="search"
                      item-key="_id"
                      hide-default-footer >
          <template v-slot:item.z="{ item }">
            <span class="text-no-wrap"> 
          
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon 
                    v-bind="attrs"
                    v-on="on"
                @click="editFeatureFlag(item) ">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                </template>
                <span>Edit Feature Flag</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon 
                    v-bind="attrs"
                    v-on="on"
                @click="deleteFeatureFlag(item) ">
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
                </template>
                <span>Delete Feature Flag</span>
              </v-tooltip>
          
            </span>
          </template>
                      
        
            

        </v-data-table>

        <v-dialog v-model="ddialog" max-width="590">
          <v-card>
            <v-card-title class="headline"><v-icon color="red">mdi-alert</v-icon> Do you really want to delete this featureFlag?</v-card-title>
            <v-card-text>This will completely delete the featureFlag.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" @click.native="ddialog = false">No!</v-btn>
              <v-btn color="green darken-1" @click="removeFeatureFlag">Yes. I know what I'm doing.</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-if="editableFeatureFlag"
          v-model="edialog"
          transition="dialog-bottom-transition"
          persistent
          scrollable
          max-width="590"
        >
          <v-card fit>
            <v-card-title>
    
              <v-toolbar color="primary" dense>
                <v-toolbar-title class="white--text">Edit FeatureFlag</v-toolbar-title>
                <v-spacer></v-spacer>
              
              </v-toolbar>
    
            </v-card-title>
            <v-card-text>
              <v-form v-model="valid" ref="form">
                <v-container fluid>
                    
                      <v-text-field
                        label="Flag ID"
                        v-model="editableFeatureFlag.id"
                        required
                      ></v-text-field>
                      <v-textarea
                        solo
                        label="Description"
                        v-model="editableFeatureFlag.description"
                      ></v-textarea  >
                      <v-checkbox
                        
                        label="Active?"
                        v-model="editableFeatureFlag.active"
                      ></v-checkbox>
            
                </v-container>
    
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="saveFeatureFlag"
                
                color="primary"
              >
                Save
              </v-btn>
              <v-btn color="secondary"  @click.stop="edialog=false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
      export default {
        data () {
          return {
            featureFlagArr: [],
            itemLimit: -1,
            search: '',
            edialog: false,
            ddialog: false,
            sdMenu: false,
            valid: false,
            editableFeatureFlag: null,
            deleteableFeatureFlag: null,
            subHeaders: [
              {text: 'Actions', align: 'left', value:"z", sortable: false},
              {text: 'ID', value: 'id', align: 'left'},
              {text: 'Active', value: 'active', align: 'left'},
              {text: 'Description', value: 'description', align: 'left'}
            ],
            currentUser: this.$store.getters.loggedInUser
          }
        },
        methods: {
          async deleteFeatureFlag (featureFlag) {
            this.ddialog = true
            this.deleteableFeatureFlag = featureFlag
          },
          async removeFeatureFlag () {
            try {
              await this.$http.featureFlag.delete(this.deleteableFeatureFlag._id)
              await this.getFeatureFlags()
              this.ddialog = false
            } catch (e) {
              console.log(e)
            }
          },
          async editFeatureFlag (featureFlag) {
            this.editableFeatureFlag = featureFlag
            // set defaults
            if (!featureFlag._id) {
              this.active = false
            }
            this.edialog = true
          },
          async saveFeatureFlag () {
            const flag = this.editableFeatureFlag
            if (flag._id) {
              await this.$http.featureFlag.update(flag)
      
              
            } else {
              await this.$http.featureFlag.add(flag)
               
              
            }
            await this.getFeatureFlags()
            this.editableFeatureFlag = {}
            this.edialog = false
          },
          async getFeatureFlags () {
            const response = await this.$http.featureFlag.list()
            this.featureFlagArr = response.data
            this.ready = true
          }
        },
        computed: {
        },
        async created () {
          await this.getFeatureFlags()
        }
      }
  </script>
  
  <style scoped>
  
  </style>
  