import Vue from 'vue'
import Vuex from 'vuex'
import http from '@/utils/http'
Vue.use(Vuex)


const toHash = function (arr, key) {
  if (!key) {
    key = '_id'
  }
  const reducer = (accumulator, target) => {
    if (target.licenses) {
      target.licenses = target.licenses.reduce(reducer, {})
    }
    if (target.sites) {
      target.sites = toHash(target.sites, 'site')
    }
    return { ...accumulator, [target[key]]: target }
  }
  return arr.reduce(reducer, {})
}
export default new Vuex.Store({
  state: {
    loggedIn: false,
    currentUser: null,
    customers: null,
    token: null,
    plugins: null,
    modules: null,
    apps: null,
    users: null
  },
  getters: {
    isLoggedIn: state => {
      return state.loggedIn
    },
    loggedInUser: state => {
      if (!state.currentUser) {
        state.currentUser = JSON.parse(sessionStorage.getItem("currentUser"))
      }
      return state.currentUser
    },
    customerIds: state => {
      return state.customers ? Object.keys(state.customers) : []
    },
    customers: state => {
     
      return state.customers 
    },
    userIds: state => {
      return state.users ? Object.keys(state.users) : []
    }
  },
  mutations: {
    logIn: (state, result) => {
      state.loggedIn = true
      state.currentUser = result.user
      state.currentUser.sites = toHash(result.user.sites, 'site')
      state.currentUser.role = state.currentUser.sites.central.roles[0].role
      state.token = result.token
      sessionStorage.setItem("currentUser",JSON.stringify(state.currentUser))
      return Promise.resolve()
    },
    logOut: (state) => {
      state.loggedIn = false
      state.currentUser = null
      state.token = null
      state.customers = null
      sessionStorage.clear()
    },
    customers: (state, customersList) => {
      state.customers = toHash(customersList)
    },
    users: (state, userList) => {
      state.users = toHash(userList)
    },
    plugins: (state, plugins) => {
      state.plugins = plugins
    },
    modules: (state, modules) => {
      state.modules = modules
    },
    apps: (state, apps) => {
      state.apps = apps
    },
    setCustomer: (state, cust) => {
      state.customers[cust._id] = cust
    },
    setUser: (state, user) => {
      state.users[user._id] = user
    },
    deleteCustomer: (state, cust) => {
      delete state.customers[cust._id]
    },
    deleteUser: (state, user) => {
      delete state.users[user._id]
    },
    setLicense: (state, license) => {
      state.customers[license.customerId].licenses[license._id] = license
    },
    deleteLicense: (state, license) => {
      delete state.customers[license.customerId].licenses[license._id]
    }

  },
  actions: {
    login ({ commit }, result) {
      commit('logIn', result)
    },
    logout ({ commit }) {
      commit('logOut')
    },
    getCustomers: async ({commit}) => {
      const response = await http.customer.list();

       commit('customers', response.data)
      
      const pluginResp = await http.plugins.list();
      commit('plugins', pluginResp.data)
      
      const moduleResp = await http.modules.list();
      commit('modules', moduleResp.data)
  
      const appsResp = await http.apps.list();
      commit('apps', appsResp.data)
        
      
        
  
    },
    setCustomers ({ commit }, customerList) {
      commit('customers', customerList)
      if (!this.state.plugins) {
        http.plugins.list().then(function (resp) {
          commit('plugins', resp.data)
        })
      }
      if (!this.state.modules) {
        http.modules.list().then(function (resp) {
          commit('modules', resp.data)
        })
      }
      if (!this.state.apps) {
        http.apps.list().then(function (resp) {
          commit('apps', resp.data)
        })
      }
    },
    setUsers ({ commit }, userList) {
      commit('users', userList)
    },
    saveUser ({commit}, user) {
      return http.user.save(user).then(function (resp) {
        resp.data.sites = toHash(resp.data.sites, 'site')
        commit('setUser', resp.data)
        return Promise.resolve(resp.data)
      })
    },
    updateCustomer ({commit}, customer) {
      return http.customer.update(customer).then(function (resp) {
        let updatedCust = resp.data
        updatedCust.licenses = toHash(updatedCust.licenses)
        commit('setCustomer', updatedCust)
        return Promise.resolve(updatedCust)
      })
    },
    addCustomer ({commit}, customer) {
      return http.customer.add(customer).then(function (resp) {
        commit('setCustomer', resp.data)
        return Promise.resolve(resp.data)
      })
    },
    deleteCustomer ({commit}, customer) {
      http.customer.delete(customer).then(function () {
        commit('deleteCustomer', customer)
      })
    },
    saveLicense ({commit, dispatch}, license) {
      commit('setLicense', license)
      return dispatch('updateCustomer', this.state.customers[license.customerId])
    },
    deleteLicense ({commit, dispatch}, license) {
      commit('deleteLicense', license)
      return dispatch('updateCustomer', this.state.customers[license.customerId])
    }
  },
  modules: {
  }
})
