<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      dark
    >
      <div class="d-flex align-center">
        <v-btn
        class="ml-10"
        icon
        @click="goHome"
        >

        
        <v-img
          alt="Integrify Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/logo.svg')"
          transition="scale-transition"
          width="130"
         
        />
      </v-btn>
       
      </div>

      <v-spacer></v-spacer>

      <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                light
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-hamburger</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, i) in navItems"
                :key="i"
                @click="gotTo(item)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
    </v-app-bar>

    <v-main>
      <router-view :key="$route.path" />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    navItems: [
      {title:"Home", route: "/"},
      {title:"Customers", route: "/customers"},
      {title:"Stats", route: "/stats"},
      {title:"Users", route: "/users"},
      {title:"License Report", route: "/reports/licenses"},
      {title:"Feature Flags", route: "/featureflags"},
      {title:"Environments", route: "/environments"},
      {title:"Notifications", route: "/notifications"},
    ]
    //
  }),
  computed: {
      loggedIn () {
        return this.$store.getters.isLoggedIn
      },
      currentUser () {
        return this.$store.getters.loggedInUser
      }
    },
  methods: {
    goHome() {
      this.$router.push("/")
    },
    gotTo(i) {
      this.$router.push(i.route)
    },
    logOut: function () {
        this.$store.dispatch('logout')
        this.$router.push({name: 'Login'})
    }
  }
};
</script>
