<template>
    <v-card v-if="ready" class="ma-10" >
      <v-card-title>
        <v-toolbar dark color="primary">
          
          <v-toolbar-title class="white--text">
              Environment
          </v-toolbar-title>
          <v-spacer></v-spacer>
         
          <v-tooltip v-if="currentUser.role === 'admin'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon 
                    v-bind="attrs"
                    v-on="on"
                    @click.native.stop="ddialog = true" dark>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete Environment</span>
          </v-tooltip>
        </v-toolbar>
      </v-card-title>
     
      <v-dialog v-model="ddialog" max-width="590">
        <v-card>
            <v-card-title class="headline"><v-icon color="red">mdi-alert</v-icon> Do you really want to delete {{ editableEnvironment.environmentId }}?</v-card-title>
            <v-card-text>This will completely the environment and its' feature flags. Any Integrify instances running on this environment will continue working with the last running feature flag set it had before this delete.</v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" @click.native="ddialog = false">No!</v-btn>
            <v-btn color="green darken-1" @click="deleteEnvironment">Yes. I know what I'm doing.</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
        <v-card>
           
            <v-card-text>
              
            <v-form v-model="valid" ref="form" lazy-validation>
                <v-text-field
                label="Environment ID"
                v-model="editableEnvironment.environmentId"
                required
                ></v-text-field>
                <v-text-field
                label="Description"
                v-model="editableEnvironment.description"
                required
                ></v-text-field>
                <v-text-field
                label="baseUrl"
                v-model="editableEnvironment.baseUrl"
                required
                ></v-text-field>
                <v-text-field
                type="password"
                label="Integrify ENV Token"
                v-model="editableEnvironment.integrifyEnvToken"
                required
                ></v-text-field>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                        Feature Flags
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
              
                      <v-data-table
                        :items-per-page="itemLimit"
                        :headers="ffHeaders"
                        :items="featureFlagArr"
                        item-key="_id"
                        hide-default-footer
                        class="elevation-1"
                      >
                    
                        <template v-slot:item._id="{ item }">
                          <span class="text-no-wrap"> 
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon 
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="removeFeatureFlag(item)">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ 'Delete Feature Flag' }}</span>
                            </v-tooltip>
                          
                          </span>
                        </template>
                        <template v-slot:item.active="{ item }">
                          <span class="text-no-wrap"> 
                            <v-checkbox
                            v-model="item.active"
                          
                          ></v-checkbox>
                          
                          </span>
                        </template>
                        <template v-slot:item.fromEnv="{ item }">
                          <span class="text-no-wrap"> 
                            <v-icon v-if="item.fromEnv" color="green darken-2">mdi-check</v-icon>
                              
                        
                          
                          </span>
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                        Notifications
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-data-table
                        :items-per-page="itemLimit"
                        :headers="notificationHeaders"
                        :items="notificationArr"
                        item-key="_id"
                        hide-default-footer
                        class="elevation-1"
                      >
                    
                        <template v-slot:item._id="{ item }">
                          <span class="text-no-wrap"> 
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon 
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="removeNotification(item)">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ 'Delete Notification' }}</span>
                            </v-tooltip>
                          
                          </span>
                        </template>
                        <template v-slot:item.active="{ item }">
                          <span class="text-no-wrap"> 
                            <v-checkbox
                            v-model="item.active"
                          
                          ></v-checkbox>
                          
                          </span>
                        </template>
                        <template v-slot:item.fromEnv="{ item }">
                          <span class="text-no-wrap"> 
                            <v-icon v-if="item.fromEnv" color="green darken-2">mdi-check</v-icon>
                          </span>
                        </template>
                        <template v-slot:item.startDate="{ item }">
                            <span class="text-xs">{{ item.startDate | moment("YYYY-MM-DD") }}</span>
                        </template>
                        <template v-slot:item.expires="{ item }">
                          <span class="text-xs">{{ item.expires | moment("YYYY-MM-DD") }}</span>
                        </template>
                        <template v-slot:item.message="{ item }">
                            <div v-html="item.message"></div>
                            
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
             

            </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-progress-circular  v-if="saving"
              :size="70"
              :width="7"
              color="orange"
              indeterminate
            ></v-progress-circular>
              <v-btn  v-if="!saving"
                  @click="submit"
              
                  color="primary"
              >
                  Save
              </v-btn>
          
            </v-card-actions>
        </v-card>
    </v-card>
  </template>
  
  
  <script>
    // import FeatureFlags from '@/components/FeatureFlags'
    export default {
      // components: {
      //   'my-featureFlags': FeatureFlags
      // },
      props: ['mongoId'],
      data () {
        return {
          tmp: '',
          search: '',
          ready: false,
          singleSelect: false,
          selected: [],
          pagination: {rowsPerPage: 10},
          valid: false,
          editableEnvironment: {},
          edialog: false,
          loading: true,
          saving: false,
          currentUser: this.$store.getters.loggedInUser,
          ddialog: false,
          allFeatureFlags:[],
          featureFlagArr: [],
          notificationArr: [],
          envFeatureFlags:[],
          masterFeatureFLags: [],
          itemLimit:-1,
          ffHeaders: [
             {text: 'Actions', value: '_id', align: 'left'},
            {text: 'Active', value: 'active', align: 'left'},
            {text: 'Saved to Env', value: 'fromEnv', align: 'left'},
            {text: 'ID', value: 'id', align: 'left'},
            {text: 'Description', value: 'description', align: 'left'}
          ],
          notificationHeaders: [
             {text: 'Actions', value: '_id', align: 'left'},
            {text: 'Active', value: 'active', align: 'left'},
            {text: 'Saved to Env', value: 'fromEnv', align: 'left'},
            {text: 'Message', value: 'message', align: 'left'},
            {text: 'Start Date', value: 'startDate', align: 'left'},
            {text: 'Expires', value: 'expires', align: 'left'},
            {text: 'Show On Login', value: 'showOnLoginPage', align: 'left'}
          ]
        }
      },
      methods: {
        editEnv () {
        },
        async submit () {
          this.saving = true;
          const env = this.editableEnvironment
          env.featureFlags = this.featureFlagArr
          env.notifications = this.notificationArr
          await this.$http.environment.update(env);
          await this.getEnvironment()
          setTimeout(() => {
            this.saving = false;
          }, 500);
         
          
        },
        async getEnvironment () {
          const response = await this.$http.environment.get(this.mongoId)
          this.editableEnvironment = response.data
          this.featureFlagArr = JSON.parse(JSON.stringify(this.editableEnvironment.featureFlags))
          this.notificationArr = JSON.parse(JSON.stringify(this.editableEnvironment.notifications))
          this.envFeatureFlags =this.featureFlagArr.filter(f => f.fromEnv);
          this.masterFeatureFlags = this.featureFlagArr.filter(f => !f.fromEnv);
          this.ready = true
          debugger;
        },
        // async getFeatureFlags () {
        //   const response = await this.$http.featureFlag.list()
        //   let masterFlags = response.data
        //   const envFlags = this.editableEnvironment.featureFlags
        //   for (let masterFlag of masterFlags) {
        //     let matchingEnvFlag = envFlags.find(f => f.id === masterFlag.id)
        //     if (matchingEnvFlag) {
        //       masterFlag.active = matchingEnvFlag.active
        //     }
        //   }
        //   this.featureFlagArr = masterFlags
        //   this.ready = true
        // },
        async deleteEnvironment () {
          try {
            await this.$http.environment.delete(this.mongoId)
            this.$router.push({name: 'Environments'})
          } catch (e) {
            console.log(e)
          }
        },
        async removeFeatureFlag (item) {
          
            this.featureFlagArr = this.featureFlagArr.filter(f => {
              return f._id != item._id
            });
          
        },
        async removeNotification (item) {
          
          this.notificationArr = this.notificationArr.filter(f => {
            return f._id != item._id
          });
        
      }
      },
      async created () {
        await this.getEnvironment()
       
        // await this.getFeatureFlags()
      }
    }
  </script>
  
  <style scoped>
  
  </style>
  