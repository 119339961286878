import axios from 'axios'

const http = axios.create()
const authHeader = function () {
  return {headers: {'Authorization': `Bearer ${sessionStorage.getItem('token')}`}}
}
http.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401 && error.config.url.indexOf('/auth') === -1) {
    window.location = '/#/login'
  } else {
    return Promise.reject(error)
  }
})

http.login = function (creds) {
  return this.post('/auth', creds)
}
http.plugins = {
  list () {
    return http.get('/admin/plugins')
  }
}
http.modules = {
  list () {
    return http.get('/admin/modules')
  }
}
http.apps = {
  list () {
    return http.get('/admin/apps')
  }
}
http.customer = {
  list () {
    return http.get('/admin/customers', authHeader())
  },
  update (customer) {
    return http.put('/admin/customers/' + customer._id, customer, authHeader())
  },
  add (customer) {
    return http.post('/admin/customers/', customer, authHeader())
  },
  delete (customer) {
    return http.delete('/admin/customers/' + customer._id, authHeader())
  }
}
http.stats = {
  license (options) {
    return http.get(`/admin/licenses/${options.licenseId}/${options.instanceId}/stats/${options.bucket}`, authHeader())
  },
  combined (bucket) {
    return http.get(`/admin/licenses/stats/${bucket}`, authHeader())
  }
}
http.reports = {
  licenses () {
    return http.get('/admin/licenses/report', authHeader())
  }
}
http.user = {
  list () {
    return http.get('/admin/users', authHeader())
  },
  save (user) {
    return http.post('/admin/users', user, authHeader())
  }
}
http.environment = {
  list () {
    return http.get('/admin/environments', authHeader())
  },
  add (environment) {
    return http.post('/admin/environments/', environment, authHeader())
  },
  get (mongoId) {
    return http.get('/admin/environments/' + mongoId, authHeader())
  },
  update (environment) {
    return http.put('/admin/environments/' + environment._id, environment, authHeader())
  },
  delete (mongoId) {
    return http.delete('/admin/environments/' + mongoId, authHeader())
  }
}
http.featureFlag = {
  list () {
    return http.get('/admin/featureFlags', authHeader())
  },
  update (featureFlag) {
    return http.put('/admin/featureFlags/' + featureFlag._id, featureFlag, authHeader())
  },
  add (featureFlag) {
    return http.post('/admin/featureFlags/', featureFlag, authHeader())
  },
  delete (featureFlagId) {
    return http.delete('/admin/featureFlags/' + featureFlagId, authHeader())
  }
}
http.notification = {
  list () {
    return http.get('/admin/notifications', authHeader())
  },
  update (notification) {
    return http.put('/admin/notifications/' + notification._id, notification, authHeader())
  },
  add (notification) {
    return http.post('/admin/notifications/', notification, authHeader())
  },
  delete (notificationId) {
    return http.delete('/admin/notifications/' + notificationId, authHeader())
  }
}
export default http

