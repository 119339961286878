<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-layout column align-center>
        <v-alert v-if="errMsg" color="error" icon="warning" value="true">
          {{errMsg}}
        </v-alert>
        <v-form v-model="valid" ref="form" lazy-validation v-on:submit.prevent="onSubmit">
          <v-text-field
            label="User Name"
            v-model="username"
            required
          ></v-text-field>
          <v-text-field type="password"
            label="Password"
            v-model="password"
            v-on:keyup.enter="login"
            required
          ></v-text-field>

          <v-btn
            @click="login"
            :disabled="!valid"
          >
            Login
          </v-btn>
        </v-form>
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
    export default {
      data: () => ({
        valid: true,
        password: '',
        username: '',
        errMsg: null
      }),
      methods: {
        login () {
          if (this.$refs.form.validate()) {
            // Native form submission is not yet supported
            this.$http.login({
              username: this.username,
              password: this.password
            }).then(response => {
              let user = response.data.profile
              user.name = user.firstname + ' ' + user.lastname
              this.$store.dispatch('login', {token: response.data.token, user: user}).then(() => {
                sessionStorage.setItem('token', response.data.token)
                this.$http.customer.list().then(response => {
                  this.$store.dispatch('setCustomers', response.data)
                  this.$router.push(sessionStorage.getItem('destination') ? JSON.parse(sessionStorage.getItem('destination')) : {name: 'home'})
                })
              })
            }).catch(err => {
              console.log(err)
              this.errMsg = err.response.data
            })
          }
        },
        clear () {
          this.$refs.form.reset()
        }
      }
    }

</script>

<style scoped>

</style>
