<template>
  <v-card v-if="ready" class="ma-10" >
    <v-card-title>
      <v-toolbar dark color="primary">
        <v-btn v-if="currentUser.role === 'admin'" class="ml-5"
               color="light-blue"
               dark
               small
               absolute
               bottom
               left
               fab
               @click="editUser()" 
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-toolbar-title class="white--text">
          Users
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          v-model="search"
          clearable
        ></v-text-field>

      </v-toolbar>
    </v-card-title>
    <v-data-table
      v-bind:headers="headers"
      v-bind:items="items"
      :items-per-page="itemLimit"
      hide-default-footer 
      v-bind:search="search"
      expand
      item-key="_id"
      
    >
        <template v-slot:item.z="{ item }">
          <span class="text-no-wrap"> 
           
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon 
                  v-bind="attrs"
                  v-on="on"
               @click="editUser(item) ">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              </template>
              <span>Edit User</span>
            </v-tooltip>
           
          </span>
        </template>
        <template v-slot:item.created="{ item }">
            <span class="text-xs">{{ item.created | moment("YYYY-MM-DD hh:mm A") }}</span>
          </template>
          <template v-slot:item.sites="{ item }">
            <span class="text-xs">{{ item.sites.central.roles[0].role }}</span>
        </template>
          
     

    </v-data-table>
    <v-dialog
      v-model="edialog">
      <v-card>
        <v-card-title v-if="editableUser._id">
          Edit User
        </v-card-title>
        <v-card-title v-else>
          Add User
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-text-field
              readonly
              label="User ID"
              v-model="editableUser._id"
              required
            ></v-text-field>
            <v-text-field
              required
              label="First Name"
              v-model="editableUser.firstname"
              
            ></v-text-field>
            <v-text-field
              required
              label="Last Name"
              v-model="editableUser.lastname"
              
            ></v-text-field>
            <v-text-field
              required
              label="email"
              v-model="editableUser.email"
            ></v-text-field>
            <v-text-field
              required
              label="Username"
              v-model="editableUser.username"
            ></v-text-field>
            <v-text-field
              type="password"
              required
              label="Password"
              v-model="editableUser.password"
            ></v-text-field>
            <v-select
              label="Role"
              v-model="editableUser.sites.central.role"
              :items="userRoles"
              required
            ></v-select>

          </v-form>
        </v-card-text>
        <v-card-actions>

          <v-btn
            @click="submit"
            color="primary"
          >
            Save
          </v-btn>
          <v-btn color="secondary" @click.stop="edialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>


<script>
  export default {
    components: {
    },
    data () {
      return {
        itemLimit: -1,
        tmp: '',
        search: '',
        ready: false,
        pagination: {rowsPerPage: 10},
        valid: false,
        editableUser: {sites: {central: {roles: []}}},
        userRoles: ['readOnly', 'admin'],
        edialog: false,
        headers: [
        {text: 'Actions', align: 'left', value:"z", sortable: false},
          {
            text: 'First Name',
            value: 'firstname',
            align: 'left'
          },
          {text: 'Last Name', value: 'lastname', align: 'left'},
          {text: 'User Name', value: 'username', align: 'left'},
          {text: 'Created', value: 'created', align: 'left'},

          {text: 'Admin', value: 'sites', align: 'left'}
        ],
        items: [],
        users: {},
        loading: true,
        currentUser: this.$store.getters.loggedInUser
      }
    },
    methods: {
      editUser: function (user) {
        if (user) {
          user = JSON.parse(JSON.stringify(user))
        }
        this.editableUser = user || {}
        if (!this.editableUser._id) {
          this.editableUser.sites = {central: {site: 'central', role: 'readOnly', roles: [{role: 'readOnly'}]}}
        } else {
          this.editableUser.sites.central.role = this.editableUser.sites.central.roles[0].role
        }
        this.edialog = true
      },
      submit: function () {
        let me = this
        this.editableUser.sites.central.roles[0].role = this.editableUser.sites.central.role
        this.$store.dispatch('saveUser', this.editableUser).then(function () {
          me.users = me.$store.state.users
          me.items = me.$store.getters.userIds.map((i) => me.users[i])
        })
        this.edialog = false
      }
    },
    mounted: function () {
      this.$http.user.list().then(response => {
        this.$store.dispatch('setUsers', response.data)
        this.users = this.$store.state.users
        this.items = this.$store.getters.userIds.map((i) => this.users[i])
        this.ready = true
      }).catch((e) => {
        console.log(e)
      })
    }
  }
</script>

<style scoped>

</style>
