<template>
  <v-card class="ma-10" >
    <v-card-title>
        <v-toolbar dark color="primary">
          <v-btn v-if="currentUser.role === 'admin'" class="ml-5"
                color="light-blue"
                dark
                small
                absolute
                bottom
                left
                fab
                @click="editNotification({})" 
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-toolbar-title class="white--text">
            Notifications
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
            clearable
          ></v-text-field>

          </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="subHeaders"
                      :items="notificationArr"
                      :items-per-page="itemLimit"
                      v-bind:search="search"
                      item-key="_id"
                      hide-default-footer >
          <template v-slot:item.z="{ item }">
            <span class="text-no-wrap"> 
          
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon 
                    v-bind="attrs"
                    v-on="on"
                @click="editNotification(item) ">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                </template>
                <span>Edit Notification</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon 
                    v-bind="attrs"
                    v-on="on"
                @click="deleteNotification(item) ">
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
                </template>
                <span>Delete Notification</span>
              </v-tooltip>
          
            </span>
          </template>
          <template v-slot:item.message="{ item }">
            <div v-html="item.message"></div>
            
         </template>
          
          <template v-slot:item.startDate="{ item }">
            <span class="text-xs">{{ item.startDate | moment("YYYY-MM-DD") }}</span>
         </template>
        <template v-slot:item.expires="{ item }">
          <span class="text-xs">{{ item.expires | moment("YYYY-MM-DD") }}</span>
        </template>
        
            

        </v-data-table>

        <v-dialog v-model="ddialog" max-width="590">
          <v-card>
            <v-card-title class="headline"><v-icon color="red">mdi-alert</v-icon> Do you really want to delete this notification?</v-card-title>
            <v-card-text>This will completely delete the notification.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" @click.native="ddialog = false">No!</v-btn>
              <v-btn color="green darken-1" @click="removeNotification">Yes. I know what I'm doing.</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-if="editableNotification"
          v-model="edialog"
          transition="dialog-bottom-transition"
          persistent
          scrollable
          max-width="590"
        >
          <v-card fit>
            <v-card-title>
    
              <v-toolbar color="primary" dense>
                <v-toolbar-title class="white--text">Edit Notification</v-toolbar-title>
                <v-spacer></v-spacer>
              
              </v-toolbar>
    
            </v-card-title>
            <v-card-text>
              <v-form v-model="valid" ref="form">
                <v-container fluid>
                  <v-select
                    :items="classifications"
                    
                    label="Class"
                    v-model="editableNotification.classification"
                  ></v-select>
                    <span>Message</span>
                    <vue-editor v-model="editableNotification.message"></vue-editor>
                     
                    <!-- <v-textarea
                    solo
                    label="Message"
                    v-model="editableNotification.message"
                    ></v-textarea  > -->
                    <v-checkbox
                    
                    label="Active?"
                    v-model="editableNotification.active"
                    ></v-checkbox>
                    <v-checkbox
                    
                    label="Show On Login Page?"
                    v-model="editableNotification.showOnLoginPage"
                    ></v-checkbox>

                    <v-menu
                    
                        :close-on-content-click="true"
                        v-model="sdMenu"
                        transition="scale-transition"
                        offset-y
                        
                        :nudge-right="40"
                        max-width="290px"
                        min-width="290px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        label="Start Date"
                        v-model="editableNotification.startDate"
                        prepend-icon="mdi-calendar"
                        required
                        ></v-text-field>
                        </template>
                        <v-date-picker v-model="editableNotification.startDate" no-title scrollable autosave>

                        </v-date-picker>
                    </v-menu>
                    <v-menu
                        :close-on-content-click="true"
                        v-model="expiresMenu"
                        transition="scale-transition"
                        offset-y
                        
                        :nudge-right="40"
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-bind="attrs"
                            v-on="on"
                            label="Expires"
                            v-model="editableNotification.expires"
                            prepend-icon="mdi-calendar"
                            required
                        ></v-text-field>
                        </template>
                    <v-date-picker v-model="editableNotification.expires" no-title scrollable autosave>

                    </v-date-picker>
                  </v-menu>
            
                </v-container>
    
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="saveNotification"
                
                color="primary"
              >
                Save
              </v-btn>
              <v-btn color="secondary"  @click.stop="edialog=false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
      import { VueEditor } from "vue2-editor";
      export default {
        components: {
            VueEditor
        },
        data () {
          return {
            notificationArr: [],
            itemLimit: -1,
            search: '',
            edialog: false,
            ddialog: false,
            sdMenu: false,
            valid: false,
            editableNotification: null,
            deleteableNotification: null,
            subHeaders: [
              {text: 'Actions', align: 'left', value:"z", sortable: false},
              {text: 'Active', value: 'active', align: 'left'},
              {text: 'Class', value: 'classification', align: 'left'},
              {text: 'Message', value: 'message', align: 'left'},
              {text: 'Start Date', value: 'startDate', align: 'left'},
              {text: 'Expires', value: 'expires', align: 'left'},
              {text: 'Show On Login', value: 'showOnLoginPage', align: 'left'}
            ],
            currentUser: this.$store.getters.loggedInUser,
            classifications: ["info","new-code","marketing", "critical"]
          }
        },
        methods: {
          async deleteNotification (notification) {
            this.ddialog = true
            this.deleteableNotification = notification
          },
          async removeNotification () {
            try {
              await this.$http.notification.delete(this.deleteableNotification._id)
              await this.getNotifications()
              this.ddialog = false
            } catch (e) {
              console.log(e)
            }
          },
          async editNotification (notification) {
            this.editableNotification = notification
            // set defaults
            if (!notification._id) {
              this.active = false
            }
            this.edialog = true
          },
          async saveNotification () {
            const flag = this.editableNotification
            if (flag._id) {
              await this.$http.notification.update(flag)
      
              
            } else {
              await this.$http.notification.add(flag)
               
              
            }
            await this.getNotifications()
            this.editableNotification = {}
            this.edialog = false
          },
          async getNotifications () {
            const response = await this.$http.notification.list()
            this.notificationArr = response.data
            this.ready = true
          }
        },
        computed: {
        },
        async created () {
          await this.getNotifications()
        }
      }
  </script>
  
  <style scoped>
  
  </style>
  