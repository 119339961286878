<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="50"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to Integrify Central
        </h1>

     
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        

        <v-row justify="center">
          <router-link
            v-for="(next, i) in navItems"
            :key="i"
            :to="next.route"
            class="subheading mx-3"
          >
            {{ next.title }}
          </router-link>
        </v-row>
      </v-col>

     
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeComp',

    data: () => ({
        navItems: [
        {title:"Home", route: "/"},
        {title:"Customers", route: "customers"},
        {title:"Stats", route: "/stats"},
        {title:"Users", route: "/users"},
        {title:"License Report", route: "/reports/licenses"},
        {title:"Feature Flags", route: "/featureflags"},
        {title:"Environments", route: "/environments"},
        {title:"Notifications", route: "/notifications"},
      ]
    }),
  }
</script>
